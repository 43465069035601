<template>
  <page-view>
    <div class="bill_header_wrapper">
      <a-row :gutter="48" class="row">
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: 0 }">
          <div class="filter_item">
            <span>流水号</span>
            <a-input placeholder="流水号" class="filter_input" v-model.trim="queryParam.no" />
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: 0 }">
          <div class="filter_item">
            <span>用户ID</span>
            <a-input placeholder="用户ID" class="filter_input" v-model.trim="queryParam.uid" />
          </div>
        </a-col>

        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: 0 }">
          <div class="filter_item">
            <span>查询时间</span>
            <a-range-picker
              class="filter_input"
              :value="[moment(queryParam.startCreateTime), moment(queryParam.endCreateTime)]"
              @change="onChangeDatePay"
            />
          </div>
        </a-col>
        <a-col :lg="4" :md="12" :sm="24" :style="{ padding: 0 }">
          <div class="time_select">
            <span
              v-for="item in shortcutDate"
              :key="item.id"
              @click="handleShortcutDate(item)"
              :class="currentShortcutDate === item.date ? 'active' : ''"
              >{{ item.title }}</span
            >
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: 0 }">
          <div class="filter_item">
            <a-button type="primary" class="query_btn" @click="$refs.table.refresh(false)">查询</a-button>
            <a-button @click="resetQueryParam">重置</a-button>
          </div>
        </a-col>
      </a-row>
    </div>

    <div class="bill_chart_wrapper">
      <!-- <a-tabs :activeKey="activeChartTabKey" @change="handleChartTabChange">
        <a-tab-pane tab="实时概况" key="realTime"></a-tab-pane>
        <a-tab-pane tab="以往数据" key="previousData"></a-tab-pane>
        <span
          slot="tabBarExtraContent"
          v-if="activeChartTabKey == 'realTime'"
          class="tab_time"
        >对比时间:{{ compareTimeTab }}</span>
        <span slot="tabBarExtraContent" v-else class="tab_time">更新时间:{{ updateTimeTab }}</span>
      </a-tabs>
      <div class="all_chart" v-if="activeChartTabKey == 'realTime'">123</div>
      <div v-else>456</div>-->

      <a-row :gutter="48" class="row">
        <a-col :md="3" :sm="24">
          <a-form-item label="系统用户数">
            <span style="font-size: 24px;">{{ countTotal.uid }}</span>
          </a-form-item>
        </a-col>
        <a-col :md="3" :sm="24">
          <a-form-item label="消费用户数">
            <span style="font-size: 24px;">{{ countTotal.payCount }}</span>
          </a-form-item>
        </a-col>
        <a-col :md="3" :sm="24">
          <a-form-item label="总邀请人数">
            <span style="font-size: 24px;">{{ countTotal.extension }}</span>
          </a-form-item>
        </a-col>
        <a-col :md="3" :sm="24">
          <a-form-item label="账户总余额">
            <span style="font-size: 24px;">{{ countTotal.balance }}</span>
          </a-form-item>
        </a-col>
        <a-col :md="3" :sm="24">
          <a-form-item label="可提现">
            <span style="font-size: 24px;">{{ countTotal.balanceWithdrawabl }}</span>
          </a-form-item>
        </a-col>
        <a-col :md="3" :sm="24">
          <a-form-item label="提现中">
            <span style="font-size: 24px;">{{ countTotal.balanceCash }}</span>
          </a-form-item>
        </a-col>
        <a-col :md="3" :sm="24">
          <a-form-item label="已提现">
            <span style="font-size: 24px;">{{ countTotal.balanceCashAll }}</span>
          </a-form-item>
        </a-col>
      </a-row>
    </div>
    <div class="bill_table_wrapper">
      <a-tabs :activeKey="activeTabKey" @change="handleTabChange">
        <a-tab-pane :tab="array.name" v-for="array in typeArray" :key="array.value"></a-tab-pane>
      </a-tabs>
      <div class="bill_table_box">
        <s-table ref="table" rowKey="id" :columns="columns" :data="dataSource">
          <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}</span>
          <!-- 流水 -->
          <template slot="no" slot-scope="text, record">
            <a-tooltip placement="right" overlayClassName="tooltip_custom">
              <template slot="title">
                <span>点击复制</span>
              </template>
              <span class="uid_fat" @click="copyInfo(record.no)" @mouseover="handleMouseover1(record)" @mouseout="handleMouseout1(record)">
                {{ text.substring(0, 4) + '……' + text.substring(text.split('').length - 4) }}
                <span class="uid_child" v-show="record.show1">{{ text }}</span>
              </span>
            </a-tooltip>
          </template>
          <!-- id -->
          <template slot="uid" slot-scope="text, record">
            <a-tooltip placement="right" overlayClassName="tooltip_custom">
              <template slot="title">
                <span>点击复制</span>
              </template>
              <span class="uid_fat" @click="copyInfo(record.uid)" @mouseover="handleMouseover2(record)" @mouseout="handleMouseout2(record)">
                {{ text.substring(0, 4) + '……' + text.substring(text.split('').length - 4) }}
                <span class="uid_child" v-show="record.show2">{{ text }}</span>
              </span>
            </a-tooltip>
          </template>
        </s-table>
      </div>
    </div>
  </page-view>
</template>

<script>
import { PageView } from '@/layouts';
import { STable } from '@/components';
import { billAdminList, accountCount } from '@api';
import { formatDate, jsonToArray, billType } from '@/utils/util';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
export default {
  data() {
    return {
      activeChartTabKey: 'realTime',
      activeTabKey: 'all',
      shortcutDate: [
        {
          id: 1,
          title: '今天',
          date: 0
        },
        {
          id: 2,
          title: '昨天',
          date: 1
        },
        {
          id: 3,
          title: '最近7天',
          date: 7
        },
        {
          id: 4,
          title: '最近30天',
          date: 30
        }
      ],
      currentShortcutDate: 0,
      typeArray: jsonToArray(billType),
      queryParam: {
        startCreateTime: new Date(new Date().toLocaleDateString()).getTime(),
        endCreateTime: new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000
      },
      countTotal: {},
      columns: [
        {
          title: '序号',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '用户ID',
          dataIndex: 'uid',
          scopedSlots: { customRender: 'uid' }
        },
        {
          title: '流水号',
          dataIndex: 'no',
          scopedSlots: { customRender: 'no' }
        },
        {
          title: '金额',
          dataIndex: 'amount',
          customRender: (text) => text + '元'
        },
        {
          title: '类型',
          dataIndex: 'type',
          customRender: (text) => billType[text]
        },
        {
          title: '描述',
          dataIndex: 'comment'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          customRender: formatDate
        }
      ],
      dataSource: (parameter) => {
        return billAdminList(Object.assign(parameter, this.queryParam)).then((result) => {
          result.data.list.forEach((item) => {
            item.show1 = false;
            item.show2 = false;
          });
          return result.data;
        });
      }
    };
  },
  created() {
    //tab栏加上全部
    let obj = {
      name: '全部',
      value: 'all'
    };
    this.typeArray.unshift(obj);

    accountCount().then((countTotal) => {
      this.countTotal = countTotal.data;
    });
  },
  methods: {
    moment,

    handleMouseover1(record) {
      record.show1 = true;
    },
    handleMouseout1(record) {
      record.show1 = false;
    },
    handleMouseover2(record) {
      record.show2 = true;
    },
    handleMouseout2(record) {
      record.show2 = false;
    },
    onChangeDatePay(date, dateString) {
      const time = dateString.includes('');
      if (time) {
        delete this.queryParam.startCreateTime;
        delete this.queryParam.endCreateTime;
      } else {
        this.queryParam.startCreateTime = Date.parse(new Date(dateString[0])) - 1000 * 3600 * 8;
        this.queryParam.endCreateTime = Date.parse(new Date(dateString[1])) - 1000 * 3600 * 8;
      }
      this.currentShortcutDate = null;
      this.$refs.table.refresh(false);
    },
    handleShortcutDate(record) {
      this.currentShortcutDate = record.date;
      //图表tab跟随时间切换
      this.currentShortcutDate == 0 ? (this.activeChartTabKey = 'realTime') : (this.activeChartTabKey = 'previousData');
      // 今天
      let startCreateTime;
      let endCreateTime;
      let today = new Date(new Date().toLocaleDateString()).getTime();
      if (record.date === 0) {
        startCreateTime = today;
        endCreateTime = today + 24 * 60 * 60 * 1000;
      }
      if (record.date === 7 || record.date === 30) {
        endCreateTime = today + 24 * 60 * 60 * 1000;
        startCreateTime = today - 24 * 60 * 60 * 1000 * (record.date - 1);
      }
      if (record.date === 1) {
        startCreateTime = today - 24 * 60 * 60 * 1000;
        endCreateTime = today;
      }
      this.queryParam.startCreateTime = startCreateTime;
      this.queryParam.endCreateTime = endCreateTime;
      this.$refs.table.refresh(false);
      this.formatTabRightTime();
    },
    resetQueryParam() {
      this.currentShortcutDate = 0;
      this.activeChartTabKey = 'realTime';
      this.queryParam = {
        startCreateTime: new Date(new Date().toLocaleDateString()).getTime(),
        endCreateTime: new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000
      };
      this.activeTabKey = 'all';
      this.$refs.table.refresh(false);
    },
    handleTabChange(e) {
      this.activeTabKey = e;
      if (e == 'all') {
        const { type, ...params } = this.queryParam;
        this.queryParam = params;
      } else {
        this.queryParam.type = e;
      }

      this.$refs.table.refresh(true);
    },

    handleChartTabChange(key) {
      this.activeChartTabKey = key;
      // this.optionSeries4 = JSON.parse(JSON.stringify(this.optionSeries5));
      this.formatTabRightTime();
      let today = new Date(new Date().toLocaleDateString()).getTime();
      if (key == 'previousData') {
        //昨天
        this.currentShortcutDate = 1;
        this.queryParam = {
          startCreateTime: today - 24 * 60 * 60 * 1000,
          endCreateTime: today
        };
      } else {
        //今天
        this.currentShortcutDate = 0;
        this.queryParam = {
          startCreateTime: today,
          endCreateTime: today + 24 * 60 * 60 * 1000
        };
      }
      this.$refs.table.refresh(false);
    },

    formatTabRightTime() {
      let date = new Date();
      let Y = date.getFullYear() + '-';
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
      let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
      let m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
      let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
      this.updateTimeTab = Y + M + D + ' ' + h + m + s;
    }
  },
  components: {
    PageView,
    STable
  },
  computed: {
    compareTimeTab() {
      let start = new Date(new Date().getTime());
      let end = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
      let Y1 = start.getFullYear();
      let M1 = start.getMonth() + 1 < 10 ? '0' + (start.getMonth() + 1) : start.getMonth() + 1;
      let D1 = start.getDate() < 10 ? '0' + start.getDate() : start.getDate();
      let Y2 = end.getFullYear();
      let M2 = end.getMonth() + 1 < 10 ? '0' + (end.getMonth() + 1) : end.getMonth() + 1;
      let D2 = end.getDate() < 10 ? '0' + end.getDate() : end.getDate();
      return Y1 + '-' + M1 + '-' + D1 + '~' + Y2 + '-' + M2 + '-' + D2;
    }
  }
};
</script>

<style lang="less" scoped>
.row {
  margin: 0 !important;
}
.bill_header_wrapper {
  padding: 24px 40px 0;
  margin: -24px -24px 24px;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);

  .filter_item {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .filter_input {
      width: 240px;
      height: 32px;
      margin-left: 6px;
    }
    .query_btn {
      margin-right: 12px;
    }
  }
  .time_select {
    span {
      display: inline-block;
      margin-right: 24px;
      height: 32px;
      line-height: 32px;
      cursor: pointer;
    }
    .active {
      color: #1890ff;
    }
  }
}
.bill_chart_wrapper {
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  margin-bottom: 24px;
  font-family: PingFang SC;
  .all_chart {
    margin-top: 8px;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    .chart_item {
      // border: 1px solid red;
    }
    .chart_info {
      text-align: left;
      padding-left: 40px;
      .num_title {
        margin-bottom: 8px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
      }
      .num_total {
        font-size: 24px;
        font-family: Roboto;
        color: rgba(0, 0, 0, 0.85);
      }
    }

    // .chart_rig_num {
    //   display: flex;
    //   flex-wrap: wrap;
    //   // 另外加上
    //   .chart_info {
    //     width: 50%;
    //     padding: 0 88px;
    //     // text-align: center;
    //     margin-bottom: 40px;
    //     &:nth-child(1) {
    //       border-right: 1px solid #f0f0f0;
    //     }
    //     .num_title {
    //       color: rgba(0, 0, 0, 0.45);
    //     }
    //     &:last-child {
    //       margin-bottom: 36px;
    //     }
    //   }
    // }
  }
}

.bill_table_wrapper {
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  padding-bottom: 24px;
  border-radius: 4px;
}
.bill_table_box {
  margin: 0 24px;
}

.tab_time {
  line-height: 53px;
}
.uid_fat {
  position: relative;
  cursor: pointer;
  .uid_child {
    position: absolute;
    bottom: -40px;
    left: 32px;
    padding: 4px 8px;
    background-color: #f0f0f0;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }
}
//tab栏
/deep/ .ant-tabs-nav .ant-tabs-tab {
  margin: 0 16px 0 16px;
  padding: 16px 12px;
}
/deep/ .ant-tabs {
  margin-right: 24px;
}
/deep/ .ant-tabs-extra-content {
  line-height: 53px;
}
</style>
